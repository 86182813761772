import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { QueryClient, QueryClientProvider } from "react-query";
//@ts-ignore
import { CloudinaryContext } from "cloudinary-react";
import { FirebaseContextProvider } from "./firebase";
import { customTheme } from "./Theme";
import "./index.css";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <CloudinaryContext cloudName="big-fish-podcast">
      <FirebaseContextProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={customTheme}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </ChakraProvider>
        </QueryClientProvider>
      </FirebaseContextProvider>
    </CloudinaryContext>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
