import React, { FC } from "react";
import { createIcon, Icon, IconProps } from "@chakra-ui/icons";

export const Spotify: FC<IconProps> = (props) => {
  return (
    <Icon
      viewBox="0 0 24 24"
      fill="text.body"
      _hover={{ fill: "#1ED760" }}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M19.098 10.638C15.23 8.341 8.85 8.13 5.158 9.251a1.122 1.122 0 1 1-.652-2.148C8.745 5.816 15.79 6.064 20.244 8.708a1.122 1.122 0 1 1-1.146 1.93m-.126 3.403a.936.936 0 0 1-1.287.308c-3.225-1.983-8.142-2.557-11.958-1.399a.937.937 0 0 1-1.167-.623.937.937 0 0 1 .624-1.167c4.358-1.322 9.776-.682 13.48 1.594.44.271.578.847.308 1.287m-1.469 3.267a.748.748 0 0 1-1.028.25c-2.818-1.723-6.365-2.112-10.542-1.158a.748.748 0 1 1-.333-1.458c4.571-1.045 8.492-.595 11.655 1.338a.748.748 0 0 1 .248 1.028M12 0C5.373 0 0 5.372 0 12s5.373 12 12 12c6.628 0 12-5.372 12-12S18.628 0 12 0"
      ></path>
    </Icon>
  );
};

export const Anchor = createIcon({
  displayName: "Anchor",
  viewBox: "0 0 500 457",
  path: (
    <path
      fill="#24203f"
      d="M483.49 148.53s-20.95-10-42.73-3.5c-16.25 4.81-34.1 17.17-44.24 42.84C377.09 237.08 393.9 323 387.27 323c-6.92 0-27.48-79.12-55.39-161.52S280-6.5 236.44.39c-38.61 6.11-26.65 103.66-11.52 195.05 13.71 82.82 29.56 160.38 20.51 160.39-16.15 0-69.28-228.95-123.76-220.25-54.48 8.7 15.7 261 1 261.45-12.13.39-41.79-153.57-85.85-146.49-33.4 5.37-3.64 125.12-2.27 158.52.34 8.49 2.94 24.12-9.62 24.12L0 433.21v23.27l40.8-.12c8.42-.41 15-3.63 18.35-11.88 11.31-27.92-18.43-146.71-11.45-147.56 4.78-.58 15.09 35.56 29.49 76.44 14.4 40.88 28.81 83.24 56.14 83.24 59 0-1.16-231.69 10.54-231.69 6.16 0 23 47.07 41.56 98.69 22.67 63.11 50.4 133.08 83.69 133.08 39.84 0 26.87-90.39 14.54-177-10.68-75-23.2-145.26-16.51-145.26 15 0 71.79 322.25 140 322.25 34.17 0 34.55-67.39 34.55-140 0-51-4.25-97.52 27.73-108.7 15.21-5.32 30.58 4.12 30.58 4.12l-16.52-63.56z"
      fillRule="evenodd"
    ></path>
  ),
});

export const ApplePod: FC<IconProps> = (props) => {
  return (
    <Icon
      viewBox="0 0 400 446"
      fill="text.body"
      _hover={{ fill: "#832BC1" }}
      {...props}
    >
      <g id="svgg">
        <path
          id="path0"
          d="M190.803 0.615 C 64.718 6.589,-24.365 126.758,6.418 249.343 C 20.017 303.498,59.519 353.439,107.591 377.252 C 122.492 384.634,137.077 368.081,127.499 354.658 C 125.827 352.315,124.614 351.415,118.635 348.082 C 72.873 322.568,41.654 278.465,33.444 227.737 C 16.714 124.359,95.634 31.419,200.146 31.419 C 306.937 31.419,386.480 128.414,365.810 233.431 C 356.174 282.386,324.272 325.313,279.866 349.074 C 270.148 354.274,266.928 362.551,271.274 371.160 C 275.363 379.260,284.744 381.498,294.015 376.585 C 358.864 342.214,399.992 273.798,399.992 200.292 C 399.992 86.543,304.335 -4.764,190.803 0.615 M189.927 62.201 C 87.916 70.465,29.346 181.667,80.594 269.781 C 87.996 282.508,101.829 299.243,107.323 302.118 C 120.638 309.086,135.027 295.331,128.571 281.806 C 127.746 280.078,126.830 278.917,123.768 275.716 C 108.626 259.884,99.230 242.589,95.075 222.900 C 78.992 146.681,145.736 79.425,221.981 95.019 C 280.015 106.889,317.276 163.592,305.387 221.945 C 301.256 242.221,291.958 259.528,276.470 275.771 C 271.555 280.926,270.089 283.874,270.077 288.625 C 270.050 299.769,282.423 307.450,292.345 302.447 C 296.668 300.268,307.499 288.327,314.380 278.155 C 359.854 210.931,338.904 120.511,268.467 79.991 C 245.421 66.734,216.064 60.084,189.927 62.201 M194.599 154.345 C 159.228 159.588,142.237 198.528,162.772 227.285 C 181.226 253.129,220.757 252.501,238.343 226.084 C 256.495 198.818,242.015 162.521,210.051 155.163 C 206.655 154.381,197.590 153.901,194.599 154.345 M193.176 277.521 C 169.536 281.366,151.622 303.614,154.155 325.985 C 154.354 327.752,154.519 329.394,154.520 329.635 C 154.524 330.543,169.386 418.102,170.072 421.264 C 174.708 442.611,197.385 452.648,216.156 441.661 C 222.458 437.972,228.241 429.910,229.913 422.482 C 230.184 421.277,230.526 419.766,230.674 419.124 C 231.083 417.348,245.528 331.679,245.669 330.198 C 245.736 329.487,245.937 327.589,246.114 325.981 C 249.189 298.161,221.592 272.900,193.176 277.521 "
          stroke="none"
          fillRule="evenodd"
        ></path>
      </g>
    </Icon>
  );
};
