export const SPOTIFY_URL =
  "https://open.spotify.com/show/3IsWkvel843DqxNcRsRbv5";

export const ANCHOR_URL = "https://anchor.fm/bigfishpodcast";

export const APPLE_POD_URL =
  "https://podcasts.apple.com/us/podcast/big-fish/id1609815302";

export const MAX_VISIBILITY = 3;

export const SPOTIFY_TRAILER =
  "https://open.spotify.com/embed-podcast/episode/51UpRUVP6RGCRXGsS1AF13";

export const BASE_IMAGE_URL = 'https://res.cloudinary.com/big-fish-podcast/image/upload/'

export const TRANSFORMATION = "w_200,ar_1:1,c_fill,g_auto/";