import React, { FC } from "react";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { Spotify } from "./CustomIcons";
import { AttachmentIcon } from "@chakra-ui/icons";

export const EpisodeTitle: FC<{
  episode: number;
  title: string;
  spotifyUrl?: string;
}> = ({ episode, title, spotifyUrl }) => {
  console.log({ spotifyUrl });
  return (
    <Flex
      style={{ position: "absolute", opacity: 0.9 }}
      flexDirection="column"
      width="80%"
      height="150px"
      background="dark.500"
      justify="center"
      align="center"
    >
      <Text textAlign="center" color="text.title" fontSize="2xl">
        Episode {episode}
      </Text>
      <Text color="text.body" fontSize="md" weight="bolder" fontStyle="italic">
        {title}
      </Text>
      <Box
        display="flex"
        marginTop={6}
        width="80%"
        justifyContent="space-around"
      >
        <Link href={spotifyUrl} isExternal>
          <Spotify boxSize="8" marginRight={2} />
          Play
        </Link>
        <Link href={`episode/${episode}`}>
          <AttachmentIcon boxSize="8" marginRight={2} />
          Details
        </Link>
      </Box>
    </Flex>
  );
};
