/* eslint-disable jsx-a11y/iframe-has-title */
import { useSpringCarouselContext } from "react-spring-carousel";
import { animated, useSpring } from "react-spring";
import { Flex, Image, useTheme } from "@chakra-ui/react";
import { IEpisode } from "../hooks";
import { CommingSoon } from "./CommingSoon";
import { SPOTIFY_TRAILER } from "../constants";
import { EpisodeTitle } from "./EpisodeTitle";

export function CarouselItem({
  id,
  index,
  image,
  available,
  alt,
  activeItem,
  name,
  trailer = false,
  spotifyUrl,
}: IEpisode & { activeItem: string; trailer?: boolean }) {
  const { colors } = useTheme();
  const { getIsNextItem, getIsPrevItem } = useSpringCarouselContext();
  const isActive = activeItem === id;
  const styles = useSpring({
    x: getIsNextItem(id) ? 22 : getIsPrevItem(id) ? -22 : 0,
    scale: isActive
      ? 1.08
      : getIsNextItem(id) || getIsPrevItem(id)
      ? 0.8
      : 0.78,
    backgroundColor: trailer
      ? "#535353"
      : isActive
      ? colors.dark["400"]
      : colors.dark["500"],
    filter: `blur(${isActive ? 0 : 5}px)`,
  });
  return (
    <AnimatedFlex
      style={styles}
      justifyContent="center"
      alignItems="center"
      cursor="grab"
      userSelect="none"
      width="100%"
      borderRadius={["0px", "0px", "50px"]}
    >
      {trailer ? (
        <Flex>
          <iframe
            src={SPOTIFY_TRAILER}
            width="100%"
            height="232"
            //@ts-ignore
            allowtransparency="true"
            allow="encrypted-media"
            style={{ alignItems: "center" }}
          ></iframe>
        </Flex>
      ) : (
        <>
          {image && (
            <Image
              fit="contain"
              src={`/assets/episodes/${image}.png`}
              alt={alt}
              pointerEvents={"none"}
              borderRadius={["0px", "0px", "50px"]}
            />
          )}
          {!available && <CommingSoon episode={index} />}
          {available && (
            <EpisodeTitle
              episode={index}
              title={name}
              spotifyUrl={spotifyUrl}
            />
          )}
        </>
      )}
    </AnimatedFlex>
  );
}

const AnimatedFlex = animated(Flex);
