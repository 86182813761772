import { useQuery } from "react-query";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

interface Document {
  title: string;
  link: string;
  isInternal?: boolean;
}

export interface IEpisode {
  index: number;
  name: string;
  description: string;
  files: string[];
  documents?: Document[];
  available: boolean;
  id: string;
  image?: string;
  alt?: string;
  spotifyUrl?: string;
}

const getEpisodes = (): Promise<IEpisode[]> => {
  return new Promise((resolve, reject) => {
    getDocs(collection(db, "episodes"))
      .then((snapshot) => {
        let episodes: IEpisode[] = [];
        snapshot.forEach((doc) =>
          episodes.push({ ...doc.data(), id: doc.id } as IEpisode)
        );
        return resolve(episodes);
      })
      .catch((error) => reject(error));
  });
};

export const useEpisodes = () => {
  return useQuery<IEpisode[], Error>("episodes", getEpisodes);
};
