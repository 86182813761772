import React, { FC, useEffect } from "react";
import { IParallax, Parallax } from "@react-spring/parallax";
import { Fish } from "./Fish";
import { Intro } from "./Intro";
import { Description } from "./Description";
import { About } from "./About";
import { Episodes } from "./Episodes";
import { Contact } from "./Contact";
import { SECTIONS } from "../../router";
import { logEvent } from "../../firebase";

interface ILanding {
  parallaxRef: React.RefObject<IParallax>;
  setActive: (active: SECTIONS) => void;
}

const Landing: FC<ILanding> = ({ parallaxRef, setActive }) => {
  useEffect(() => {
    logEvent({ event: "page_view", params: { page_path: "/" } });
  }, []);
  return (
    <Parallax ref={parallaxRef} pages={5.1}>
      <Fish offset={0} />
      <Intro offset={0} setActive={setActive} />
      <Description offset={1} setActive={setActive} />
      <Episodes offset={2} setActive={setActive} />
      <About offset={3} setActive={setActive} />
      <Contact offset={4} setActive={setActive} />
    </Parallax>
  );
};

export { Landing };
