import React, { FC, useEffect, useState } from "react";
import { AttachmentIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import { IFeat, useEpisode } from "../hooks";
import { getDoc } from "firebase/firestore";
import { FeatCarousel } from "../components";
import { Documents } from "../components/Documents";

interface IEpisode {}

const getRef = async (feats: any[]) => {
  const promises = feats.map((feat) => {
    const test = getDoc(feat).then((res) => res.data());
    return test;
  });
  return Promise.all(promises);
};

const Episode: FC<IEpisode> = () => {
  const [feats, setFeats] = useState<IFeat[]>();
  const params = useParams();
  const { data } = useEpisode(params?.id ?? "");
  useEffect(() => {
    const feats = data?.feat;
    if (feats) {
      const ft = getRef(feats);
      ft.then((res) => setFeats(res as IFeat[]));
    }
  }, [data]);
  const description = data?.description ?? "";
  return (
    <Flex paddingTop="120px" margin="4" flexDirection="column">
      <Heading color="text.title">{data?.name}</Heading>
      {description.split("<br/>").map((item) => (
        <Text color="text.body" key={item} marginTop="20px">
          {item}
        </Text>
      ))}
      <Flex marginTop="25" flexDirection="column">
        <Heading size="md" color="text.title">
          Who's Who
        </Heading>
        <HStack spacing="10" overflowX="auto" overflowY="hidden" paddingY="15">
          {feats && <FeatCarousel feats={feats} />}
        </HStack>
      </Flex>
      <Flex marginTop="25" flexDirection="column">
        <Heading size="md" color="text.title">
          Documents
        </Heading>
        <Documents documents={data?.documents ?? []} />
      </Flex>
    </Flex>
  );
};

export { Episode };
