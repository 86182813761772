import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { FC } from "react";

interface IDot {
  activeItem: string;
  slideTo: () => void;
  id: string;
}

export const Dot: FC<IDot> = ({ activeItem, id, slideTo }) => {
  const isActive = activeItem === id;
  const onClick = () => {
    slideTo();
  };
  return (
    <StyledButton
      key={`dot-${id}`}
      size="dot"
      variant={"round"}
      bg={isActive ? "dark.100" : "dark.500"}
      onClick={onClick}
    />
  );
};

const StyledButton = styled(Button)`
  width: 25px;
  height: 25px;
  border-radius: 100px;
`;
