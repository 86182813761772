import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { BASE_IMAGE_URL, TRANSFORMATION } from "../constants";
import { IFeat } from "../hooks";
import { PersonDetails } from "./PersonDetail";

const FeatCarousel: FC<{ feats: IFeat[] }> = ({ feats }) => {
  const [persona, setPersona] = useState<IFeat | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onClick = (feat: IFeat) => {
    setPersona(feat);
    onOpen();
  };
  return (
    <>
      {persona && <PersonDetails {...{ isOpen, onClose }} {...persona} />}
      {feats.map((item) => (
        <Box
          borderWidth="1px"
          borderColor="dark.400"
          borderRadius="20px"
          minWidth="200px"
          onClick={() => onClick(item)}
        >
          <Image
            src={`${BASE_IMAGE_URL}${TRANSFORMATION}${item.photo_url}`}
            alt={item.name}
          />
          <Flex justifyContent="center" alignItems="center">
            <Text>{item.name}</Text>
          </Flex>
        </Box>
      ))}
    </>
  );
};

export { FeatCarousel };
