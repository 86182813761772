import { ParallaxLayer } from "@react-spring/parallax";
import React, { FC, useEffect, useMemo, useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { db } from "../../firebase";
import { SECTIONS } from "../../router";
import { useIntersection } from "../../hooks";

interface IContact {
  offset: number;
  setActive: (active: SECTIONS) => void;
}

interface IForm {
  name: string;
  email: string;
  comment: string;
}

const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const Contact: FC<IContact> = ({ offset, setActive }) => {
  const [value, setValue] = React.useState<IForm>({
    name: "",
    email: "",
    comment: "",
  });
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const disabled = useMemo(() => {
    if (validateEmail(value.email)) {
      if (value.name && value.comment) {
        return false;
      }
      return true;
    }
    return true;
  }, [value]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await addDoc(collection(db, "contacts"), { ...value });
      setValue({ name: "", email: "", comment: "" });
      setLoading(false);
      toast({
        title: "Message received.",
        description: "Thanks for your message!.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      setValue({ name: "", email: "", comment: "" });
      setLoading(false);
      toast({
        title: "Message was not received.",
        description: "Oops! something didn't work.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const [containerRef, isVisible] = useIntersection({
    threshold: 0,
    root: null,
    rootMargin: "",
  });

  useEffect(() => {
    if (isVisible) {
      setActive(SECTIONS.CONTACT);
    }
  }, [isVisible, setActive]);

  return (
    <Box>
      <ParallaxLayer offset={offset + 0.3} speed={1}>
        <Box margin="4" ref={containerRef}>
          <Heading color="text.title">Drop us a line</Heading>
          <Text color="text.body">
            Got a tip? Or want to get in touch with Maeve and Sophia? Please
            fill the form below.
          </Text>
        </Box>
      </ParallaxLayer>
      <ParallaxLayer offset={offset + 0.5} speed={1}>
        {loading ? (
          <Box margin="4" align="center">
            <Spinner />
          </Box>
        ) : (
          <VStack spacing="4" margin="4rem">
            <Box
              display="flex"
              flexDirection={["column", "column", "row"]}
              spacing="4"
              width={["20rem", "50rem"]}
            >
              <FormControl>
                <FormLabel htmlFor="name">Full Name</FormLabel>
                <Input
                  value={value.name}
                  onChange={(event) =>
                    setValue((oldValue) => ({
                      ...oldValue,
                      name: event.target.value,
                    }))
                  }
                  name="name"
                />
              </FormControl>
              <Box width={[0, 0, "4rem"]} />
              <FormControl>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={value.email}
                  onChange={(event) =>
                    setValue((oldValue) => ({
                      ...oldValue,
                      email: event.target.value,
                    }))
                  }
                />
              </FormControl>
            </Box>
            <FormControl width={["20rem", "50rem"]}>
              <FormLabel htmlFor="tip">Tip or comment</FormLabel>
              <Textarea
                id="text-input-id"
                name="comment"
                value={value.comment}
                onChange={(event) =>
                  setValue((oldValue) => ({
                    ...oldValue,
                    comment: event.target.value,
                  }))
                }
              />
            </FormControl>
            <Box direction="row" gap="medium">
              <Button
                disabled={disabled}
                onClick={onSubmit}
                width={["20rem", "50rem"]}
                leftIcon={<EmailIcon />}
                background="brand"
                variant="solid"
              >
                Submit
              </Button>
            </Box>
          </VStack>
        )}
      </ParallaxLayer>
    </Box>
  );
};

export { Contact };
