import React, { FC } from "react";
import { Image, Flex } from "@chakra-ui/react";
import { ParallaxLayer } from "@react-spring/parallax";

interface ILogo {
  offset: number;
}

const Fish: FC<ILogo> = ({ offset }) => {
  return (
    <ParallaxLayer offset={offset + 0.2} factor={2} speed={0.1}>
      <Flex
        width="100vw"
        height="100vh"
        justify="center"
        align={["center", "center", "center"]}
      >
        <Image
          width={["100%", "container.lg", "container.lg"]}
          src="/assets/fish.png"
          style={{ opacity: 0.7 }}
          alt="big fish"
        />
      </Flex>
    </ParallaxLayer>
  );
};

export { Fish };
