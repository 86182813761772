import { useEffect, useLayoutEffect, useState } from "react";
import { useSpringCarousel } from "react-spring-carousel";
import { CarouselItem } from "./CarouselItem";
import { Box, Flex } from "@chakra-ui/react";
import { IEpisode } from "../hooks";
import { breakpoints } from "../utils/mediaQueries";
import { Dot } from ".";
import styled from "@emotion/styled";

export default function Carousel({ episodes }: { episodes: IEpisode[] }) {
  console.table({ episodes });
  const [itemsPerSlide, setItemsPerSlide] = useState(3);
  useLayoutEffect(() => {
    const desktopDevice = window.matchMedia(
      `(min-width: ${breakpoints.desktop + 1}px)`
    );
    const tabletDevice = window.matchMedia(
      `(min-width: ${breakpoints.tabletSM + 1}px) and (max-width: ${
        breakpoints.desktop
      }px)`
    );
    const tabletSmDevice = window.matchMedia(
      `(max-width: ${breakpoints.tabletSM}px)`
    );

    if (desktopDevice.matches) {
      setItemsPerSlide(3);
    } else if (tabletSmDevice.matches) {
      setItemsPerSlide(1);
    } else if (tabletDevice.matches) {
      setItemsPerSlide(3);
    } else {
      setItemsPerSlide(3);
    }

    tabletSmDevice.addListener((e) => {
      if (e.matches) {
        setItemsPerSlide(1);
      }
    });
    tabletDevice.addListener((e) => {
      if (e.matches) {
        setItemsPerSlide(3);
      }
    });
    desktopDevice.addListener((e) => {
      if (e.matches) {
        setItemsPerSlide(3);
      }
    });
  }, []);

  const [activeItem, setActiveItem] = useState(episodes[0].id);
  const {
    carouselFragment,
    useListenToCustomEvent,
    slideToItem,
    thumbsFragment,
    slideToNextItem,
    slideToPrevItem,
  } = useSpringCarousel({
    itemsPerSlide,
    withLoop: true,
    initialStartingPosition: "center",
    withThumbs: true,
    items: episodes.map((episode) => ({
      id: episode.id,
      renderItem: (
        <CarouselItem {...episode} key={episode.id} activeItem={activeItem} />
      ),
      renderThumb: (
        <Dot
          id={episode.id}
          activeItem={activeItem}
          slideTo={() => slideToItem(episode.id)}
        />
      ),
    })),
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideStartChange") {
      setActiveItem(event.nextItem.id);
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.key === "ArrowRight") {
        slideToNextItem();
      } else if (event.key === "ArrowLeft") {
        slideToPrevItem();
      }
    });
  }, []);

  return (
    <Box width="100%" maxWidth="100%">
      {carouselFragment}
      <StyledFlex width="100%">{thumbsFragment}</StyledFlex>
    </Box>
  );
}

const StyledFlex = styled(Flex)`
  .use-spring-carousel-thumbs-wrapper {
    margin-top: 50px;
    justify-content: center;
    & > * {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
