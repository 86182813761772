import { extendTheme } from "@chakra-ui/react";

export const customTheme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
  fonts: {
    // heading: 'Open Sans',
    body: "Courier",
  },
  components: {
    Link: {
      defaultProps: {
        _hover: {
          textDecoration: "none",
        },
      },
    },
    Button: {
      sizes: {
        dot: {
          h: "14px",
          w: "14px",
          borderRadius: "7px",
        },
      },
    },
  },
  colors: {
    brand: "#51C71A",
    transparent: "transparent",
    black: "#0e0e0e",
    white: "#FFFCF2",
    text: {
      title: "#F5F5F5",
      body: "#B8B8B8",
    },
    dark: {
      100: "#8E8E8E",
      200: "#727272",
      300: "#323232",
      400: "#202020",
      500: "#171717",
    },
    light: {
      100: "#F0F0F0",
      200: "#E0E0E0",
      300: "#D0D0D0",
      400: "#C0C0C0",
      500: "#A7A7A7",
    },
  },
  shadows: {
    small: `0px 1.5px 3.8px rgba(0, 0, 0, 0.018), 0px 5px 14px 2px rgba(0, 0, 0, 0.05);`,
    medium: `0px 1.5px 3.8px rgba(0, 0, 0, 0.03), 0px 8px 18px rgba(0, 0, 0, 0.092)`,
    large: `0.9px 0.9px 2px rgba(0, 0, 0, 0.009), 3.1px 2.9px 6.7px rgba(97, 66, 66, 0.016),2px 13px 30px rgba(0, 0, 0, 0.12)`,
  },
});
