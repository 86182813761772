import { AttachmentIcon } from "@chakra-ui/icons";
import { Link, List, ListIcon, ListItem, Spinner } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

interface IDoc {
  title: string;
  link: string;
  isInternal?: boolean;
}

interface IDocuments {
  documents: IDoc[];
}

const Documents: FC<IDocuments> = ({ documents }) => {
  return (
    <List spacing="2" marginLeft={["0", "0", "25"]} marginTop="15">
      {documents.map((doc) => (
        <Item key={doc.title} {...doc} />
      ))}
    </List>
  );
};

const Item: FC<IDoc> = (doc) => {
  const [loading, setLoading] = useState(false);
  const download = (file: string) => {
    const starsRef = ref(storage, `episode-1/${file}`);
    setLoading(true);
    getDownloadURL(starsRef)
      .then((url) => {
        setLoading(false);
        window.open(url, "_blank");
      })
      .catch((error) => {
        setLoading(false);
        switch (error.code) {
          case "storage/object-not-found":
            console.log("File doesn't exis");
            break;
          case "storage/unauthorized":
            console.log("User doesn't have permission to access the object");
            break;
          case "storage/canceled":
            console.log("User canceled the upload");
            break;
          case "storage/unknown":
            console.log("Unknown error occurred, inspect the server response");
            break;
        }
      });
  };
  const onClick = (doc: IDoc) => {
    if (doc.isInternal) {
      download(`${doc.title}.pdf`);
    }
  };
  return (
    <ListItem color="text.body" cursor="pointer" onClick={() => onClick(doc)}>
      {loading ? <Spinner /> : <ListIcon as={AttachmentIcon} color="brand" />}
      {doc.isInternal ? (
        doc.title
      ) : (
        <Link href={doc.link} isExternal>
          {doc.title}
        </Link>
      )}
    </ListItem>
  );
};

export { Documents };
