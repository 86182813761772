import React, { FC, useRef, useState } from "react";
import { Flex, HStack, Link, Heading, Button } from "@chakra-ui/react";
import { IParallax } from "@react-spring/parallax";
import { ArrowLeftIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Drawer } from "./Drawer";
import { APPLE_POD_URL, SPOTIFY_URL } from "../constants";
import { ApplePod, Spotify } from "./CustomIcons";
import { SECTIONS } from "../router";
import { useLocation, useNavigate } from "react-router";

interface IHeader {
  parallaxRef: React.RefObject<IParallax>;
  active: SECTIONS;
}

const Header: FC<IHeader> = ({ parallaxRef, active }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isEpisode = pathname.includes("episode");
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClick = (index: number) => {
    isOpen && onClose();
    parallaxRef.current?.scrollTo(index);
  };
  const onLeftClick = () => {
    navigate("/");
  };
  const btnRef = useRef<HTMLButtonElement>(null);

  const content = [
    <Heading
      key="home"
      onClick={() => onClick(0)}
      as={Link}
      size="md"
      color={active === SECTIONS.HOME ? "brand" : "text.body"}
    >
      Home
    </Heading>,
    <Heading
      key="premise"
      onClick={() => onClick(1.3)}
      as={Link}
      size="md"
      color={active === SECTIONS.STORY ? "brand" : "text.body"}
    >
      Story
    </Heading>,
    <Heading
      key="episodes"
      onClick={() => onClick(2)}
      as={Link}
      size="md"
      color={active === SECTIONS.EPISODES ? "brand" : "text.body"}
    >
      Episodes
    </Heading>,
    <Heading
      key="About"
      onClick={() => onClick(3.1)}
      as={Link}
      size="md"
      color={active === SECTIONS.ABOUT ? "brand" : "text.body"}
    >
      About
    </Heading>,
    <Heading
      kye="contact"
      onClick={() => onClick(4)}
      as={Link}
      size="md"
      color={active === SECTIONS.CONTACT ? "brand" : "text.body"}
    >
      Contact
    </Heading>,
  ];
  return (
    <Flex
      paddingX="4"
      pos="absolute"
      top="0"
      left="0"
      height="80px"
      bg="dark.500"
      width="100%"
      opacity="0.9"
      align={"center"}
      justify={["end", "end", "center"]}
      zIndex={9}
    >
      <HStack spacing="24px" display={["none", "none", "block"]}>
        {!isEpisode && content}
      </HStack>
      {!isEpisode && (
        <Button
          display={["block", "bloc", "none"]}
          background="transparent"
          ref={btnRef}
          onClick={onOpen}
        >
          <HamburgerIcon color="white" w={6} h={6} />
        </Button>
      )}
      <HStack position="absolute" right="8" display={["none", "none", "block"]}>
        <Link href={SPOTIFY_URL} isExternal>
          <Spotify boxSize="8" />
        </Link>
        <Link href={APPLE_POD_URL} isExternal>
          <ApplePod boxSize="8" />
        </Link>
      </HStack>
      {isEpisode && (
        <Button
          position="absolute"
          left="0"
          background="transparent"
          ref={btnRef}
          onClick={onLeftClick}
          _hover={{}}
        >
          <ArrowLeftIcon color="white" w={6} h={6} />
        </Button>
      )}
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        content={content}
      />
    </Flex>
  );
};

export { Header };
