import React, { createContext, FC } from "react";
import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  EventNameString,
  EventParams,
  getAnalytics,
  logEvent as FirebaseLogEvent,
} from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAMNP6GuJgWMJmkgDQ-DVLvSuKuc1srhNk",
  authDomain: "bigfish-eb3ef.firebaseapp.com",
  projectId: "bigfish-eb3ef",
  storageBucket: "bigfish-eb3ef.appspot.com",
  messagingSenderId: "715937147179",
  appId: "1:715937147179:web:e060cadb91f9df0109fd6e",
  measurementId: "G-LEGRKZE6PG",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const analytics = getAnalytics(app);
export const storage = getStorage(app);

export const logEvent = ({
  event,
  params,
}: {
  event: EventNameString;
  params?: EventParams;
}) => {
  //@ts-ignore
  FirebaseLogEvent(analytics, event, params);
};

type FirestoreType = Firestore;

interface IFirebase {
  db: FirestoreType;
}

const FirebaseContext = createContext<IFirebase>({ db });

export const FirebaseContextProvider: FC = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ db }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase: () => IFirebase = () => {
  return React.useContext(FirebaseContext);
};
