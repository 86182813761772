import React, { FC, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { ParallaxLayer } from "@react-spring/parallax";
import { useEpisodes, useIntersection } from "../../hooks";
import { SECTIONS } from "../../router";
import Carousel from "../../components/Carousel";

interface IEpisodes {
  offset: number;
  setActive: (active: SECTIONS) => void;
}

const Episodes: FC<IEpisodes> = ({ offset, setActive }) => {
  const { data: episodes = [], isLoading } = useEpisodes();
  const [containerRef, isVisible] = useIntersection({
    threshold: 0,
    root: null,
    rootMargin: "",
  });

  useEffect(() => {
    if (isVisible) {
      setActive(SECTIONS.EPISODES);
    }
  }, [isVisible, setActive]);

  if (episodes.length === 0) return null;

  return (
    <ParallaxLayer offset={offset + 0.4} factor={0.5} speed={0.5}>
      <Flex
        width="100%"
        justify="center"
        align="center"
        direction="row"
        ref={containerRef}
      >
        <Carousel episodes={episodes} />
      </Flex>
    </ParallaxLayer>
  );
};

export { Episodes };
