import React, { FC } from "react";
import { Flex, Text } from "@chakra-ui/react";

export const CommingSoon: FC<{ episode: number }> = ({ episode }) => {
  return (
    <Flex
      style={{ position: "absolute", opacity: 0.9 }}
      flexDirection="column"
      width="80%"
      height="100px"
      background="dark.500"
      justify="center"
      align="center"
    >
      <Text textAlign="center" color="text.title" fontSize="2xl">
        Episode {episode}
      </Text>
      <Text color="text.body" fontSize="md" weight="bolder" fontStyle="italic">
        Coming soon
      </Text>
    </Flex>
  );
};
