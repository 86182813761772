import React, { FC, useEffect } from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useIntersection } from "../../hooks";
import { SECTIONS } from "../../router";

interface IDescription {
  offset: number;
  setActive: (active: SECTIONS) => void;
}

const Description: FC<IDescription> = ({ offset, setActive }) => {
  const [containerRef, isVisible] = useIntersection({
    threshold: 0,
    root: null,
    rootMargin: "",
  });

  useEffect(() => {
    if (isVisible) {
      setActive(SECTIONS.STORY);
    }
  }, [isVisible, setActive]);

  return (
    <Flex
      width="100vw"
      height="100vh"
      justify="center"
      direction={["column", "column", "row"]}
    >
      <ParallaxLayer offset={offset + 0.3} speed={0.3}>
        <Box width={["100vw", "100vw", "container.sm"]} ref={containerRef}>
          <Image
            width="100%"
            src="/assets/handshake.png"
            style={{ opacity: 0.4 }}
            alt="Curt Weldon and Gaddafi"
          />
        </Box>
      </ParallaxLayer>
      <ParallaxLayer
        offset={offset + 0.4}
        speed={0.1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box width="container.md" margin="4">
          <Text
            fontWeight="bold"
            fontSize={["3xl", "3xl", "4xl"]}
            lineHeight="normal"
            color="main"
            margin="medium"
            textAlign={["center", "center", "start"]}
          >
            The bizarre and convoluted true story of how a former U.S.
            congressman, a Philadelphia attorney, and a Las Vegas loan shark
            tried to get their hands on a dead dictator’s hidden treasure.
          </Text>
        </Box>
      </ParallaxLayer>
    </Flex>
  );
};

export { Description };
