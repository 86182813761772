import React, { useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary, Header } from "./components";
import { Landing, Episode } from "./pages";
import { IParallax } from "@react-spring/parallax";

export enum SECTIONS {
  HOME = "home",
  STORY = "story",
  ABOUT = "about",
  EPISODES = "episodes",
  CONTACT = "contact",
}

const Router: React.FC = () => {
  const [active, setActive] = useState<SECTIONS>(SECTIONS.HOME);
  const parallaxRef = useRef<IParallax>(null);
  return (
    <Box width="100vw" height="100vh" overflow="auto" bg="black">
      <Header parallaxRef={parallaxRef} active={active} />
      <ErrorBoundary>
        <Routes>
          <Route
            path="/"
            element={
              <Landing parallaxRef={parallaxRef} setActive={setActive} />
            }
          />
          <Route path="/episode/:id" element={<Episode />} />
        </Routes>
      </ErrorBoundary>
    </Box>
  );
};

export default Router;
