import React, { FC } from "react";
import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
} from "@chakra-ui/react";
import { IFeat } from "../hooks";

interface Persona extends IFeat {
  isOpen: boolean;
  onClose: () => void;
}

const PersonDetails: FC<Persona> = ({
  isOpen,
  onClose,
  name,
  photo_url,
  description,
}) => {
  const [isDesktop] = useMediaQuery("(min-width: 1280px)");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isDesktop ? "lg" : "full"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg="black">
        <ModalHeader>{name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="text.body">{description}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { PersonDetails };
