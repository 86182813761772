import { useQuery } from "react-query";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { IEpisode } from "./useEpisodes";

export interface IFeat {
  photo_url: string;
  name: string;
  description?: string;
}

interface IExtendedEpisode extends IEpisode {
  feat?: IFeat[];
}

const getEpisode = (id: string): Promise<IExtendedEpisode> => {
  return new Promise((resolve, reject) => {
    const EpisodesColleciton = collection(db, "episodes");
    const EpisodeDoc = doc(EpisodesColleciton, `episode-${id}`);
    getDoc(EpisodeDoc)
      .then((snapshot) => {
        return resolve({
          ...snapshot.data(),
          id: snapshot.id,
        } as IExtendedEpisode);
      })
      .catch((error) => reject(error));
  });
};

export const useEpisode = (id: string) => {
  return useQuery<IExtendedEpisode, Error>("episode", () => getEpisode(id));
};
