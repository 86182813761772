import React, { FC } from "react";
import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Link,
  VStack,
} from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import { Anchor, ApplePod, Spotify } from "./CustomIcons";
import { ANCHOR_URL, APPLE_POD_URL, SPOTIFY_URL } from "../constants";

interface IDrawer {
  isOpen: boolean;
  onClose: () => void;
  finalFocusRef: React.RefObject<FocusableElement>;
  content: React.ReactNode;
}

export const Drawer: FC<IDrawer> = ({
  isOpen,
  onClose,
  finalFocusRef,
  content,
}) => {
  return (
    <ChakraDrawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={finalFocusRef}
    >
      <DrawerOverlay />
      <DrawerContent background="dark.500">
        <DrawerCloseButton />
        <DrawerHeader />
        <DrawerBody>
          <VStack marginTop="8" spacing="12" alignItems="flex-start">
            {content}
          </VStack>
        </DrawerBody>
        <DrawerFooter justifyContent="space-between">
          <Link href={SPOTIFY_URL} isExternal >
            <Spotify boxSize="12" fill="#1ED760" />
          </Link>
          <Link href={ANCHOR_URL} isExternal>
            <Anchor boxSize="12" color="#24203f" />
          </Link>
          <Link href={APPLE_POD_URL} isExternal>
            <ApplePod boxSize="12" fill="#832BC1"/>
          </Link>
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
};
