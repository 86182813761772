import React, { FC, useEffect } from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import { Flex, Heading } from "@chakra-ui/react";
import { SECTIONS } from "../../router";
import { useIntersection } from "../../hooks";

interface IIntro {
  offset: number;
  setActive: (active: SECTIONS) => void;
}

const Intro: FC<IIntro> = ({ offset, setActive }) => {
  const [containerRef, isVisible] = useIntersection({
    threshold: 0,
    root: null,
    rootMargin: "",
  });

  useEffect(()=> {
    if(isVisible) {
      setActive(SECTIONS.HOME)
    }
  }, [isVisible, setActive])

  return (
    <ParallaxLayer offset={offset + 0.3} factor={1.2} speed={0.5}>
      <Flex
        width="100vw"
        height="100vh"
        justify="start"
        align="center"
        flexDirection="column"
      >
        <Heading as='h1' ref={containerRef} color="text.title" fontSize={["80px", "80px", "150px"]}>
          BIG FISH
        </Heading>
      </Flex>
    </ParallaxLayer>
  );
};

export { Intro };
