import React, { FC, useEffect } from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import { Box, Heading, Text, Link, Image, Flex } from "@chakra-ui/react";
import { useIntersection } from "../../hooks";
import { SECTIONS } from "../../router";

interface IAbout {
  offset: number;
  setActive: (active: SECTIONS) => void;
}

const MAEVE_SITE = "https://maeveallsup.com/";
const SOPHIA_SITE = "https://sophiamyszkowski.com/";

const About: FC<IAbout> = ({ offset, setActive }) => {
  const [containerRef, isVisible] = useIntersection({
    threshold: 0,
    root: null,
    rootMargin: "",
  });

  useEffect(() => {
    if (isVisible) {
      setActive(SECTIONS.ABOUT);
    }
  }, [isVisible, setActive]);

  return (
    <Box>
      <ParallaxLayer offset={offset + 0.2} speed={1}>
        <Heading
          ref={containerRef}
          color={"text.title"}
          textAlign={["center", "center", "start"]}
          marginLeft="4"
        >
          About
        </Heading>
      </ParallaxLayer>
      <ParallaxLayer offset={offset + 0.3} speed={0.7}>
        <Box direction={["column", "column", "row"]} margin="4">
          <Box pad="medium" width="xlarge">
            <Text color="text.body" textAlign={["center", "center", "start"]}>
              Big Fish is a five-part podcast independently produced by{" "}
              <Link href={MAEVE_SITE} color="brand" target="_blank">
                Maeve Allsup
              </Link>{" "}
              and{" "}
              <Link href={SOPHIA_SITE} color="brand" target="_blank">
                Sophia Myszkowski
              </Link>
              . Each episode follows their year-long investigation into an
              international financial scam that lured two prominent
              Pennsylvanians to Accra, Ghana, where they put up millions of
              dollars of their own money in an attempt to get their hands on
              $350 million in cash.
              <br />
              <br />
              <Text style={{ fontStyle: "italic" }}>
                Interested in learning more? Check out each episode's
                information page to view documents, photos, and other materials
                mentioned.
              </Text>
            </Text>
          </Box>
          <Flex width="100vw" justify="center" alignItems="center">
            <Image
              width={["sm", "sm", "container.sm"]}
              objectFit="contain"
              src={`/assets/maeve&sophia.png`}
              alt="Maeve Allsup and Sophia Myszkowski"
            />
          </Flex>
        </Box>
      </ParallaxLayer>
    </Box>
  );
};

export { About };
